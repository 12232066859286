import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  word-wrap: break-word;
}
body {
  font-family: ${(props) => props.theme.font.main};
  font-weight: normal;
}
a {
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
button {
  border: none;
  border-radius: 0;
  box-sizing: border-box;
}
.global-modal {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 2;
  transition:width .2s ease,height .2s ease,left .2s ease,top .2s ease;
}

.clock-modal {
  position: absolute;
  height: 100%;
  width: 100%;
  height: 100%;
  ${(props) => props.theme.breakpoints.up('sm')} {
    width: 546px;
    height: 450px;
    top: 120px;
    left: 132px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    top: 10px;
    left: 308px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 578px;
    height: 545px;
    top: 80px;
    left: 545px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 712px;
    height: 630px;
    top: 145px;
    left: 748px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    width: 928px;
    height: 800px;
    top: 160px;
    left: 1125px;
  }
  &  .global-modal-content {
    border: none
  }
}

.project-modal {
  width: 100%;
  height: 100%;
  ${(props) => props.theme.breakpoints.up('sm')} {
    width: 678px;
    height: 440px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 775px;
    height: 504px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 1008px;
    height: 652px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    width: 1348px;
    height: 874px;
  }

  &  .global-modal-content {
    background-color: ${(props) => props.theme.palette.primary.light};
  }
}

.projects-modal {
  width: 100%;
  height: 100%;

  ${(props) => props.theme.breakpoints.up('sm')} {
    width: 710px;
    max-height: 450px;
    top: 80px;
    left: 20px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 710px;
    max-height: 450px;
    top: 30px;
    left: 200px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 1100px;
    max-height: 600px;
    top: 40px;
    left: 300px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 1240px;
    max-height: 775px;
    left: 500px;
    top: 30px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    width: 1410px;
    max-height: 1120px;
    left: 790px
  }
  &  .global-modal-content {  
    ${(props) => props.theme.breakpoints.up('sm')} {
      border: none
  }
  }
}

.projects-modal .cancel-drag {
  padding-right: 0;
  padding-left: 0;
  margin-right: 2px;
}

.project-modal .cancel-drag {
  padding-right: 0;
  padding-left: 0;
  margin-right: 2px;
}

.projects-modal .global-modal-content {
  overflow: unset;
  border: none;
}

.project-modal .global-modal-content {
  overflow: unset;
  border: none;
  height: 100vh;

  ${(props) => props.theme.breakpoints.up('sm')} {
    height: 100%
  }
}

.about-us-modal {
  width: 100%;
  height: 100%;
  ${(props) => props.theme.breakpoints.up('sm')} {
    width: 560px;
    height: 360px;
    top: 26px;
    left: 26px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 560px;
    height: 360px;
    top: 31px;
    left: 94px
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 857px;
    height: 500px;
    top: 83px;
    left: 173px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 985px;
    height: 655px;
    top: 43px;
    left: 181px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    width: 1155px;
    height: 715px;
    top: 50px;
    left: 278px;
  }
}
.paint-modal {
  width: 100%;
  height: 100%;
  ${(props) => props.theme.breakpoints.up('sm')} {
    width: 537px;
    height: 327px;
    top: 250px;
    left: 157px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 537px;
    height: 327px;
    top: 220px;
    left: 434px
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 671px;
    height: 401px;
    top: 170px;
    left: 688px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 762px;
    height: 452px;
    top: 354px;
    left: 1085px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    width: 762px;
    height: 452px;
    top: 722px;
    left: 1540px;
  }
}
.contact-us-modal {
  width: 100%;
  height: 100%;
  ${(props) => props.theme.breakpoints.up('sm')} {
    width: 504px;
    height: 418px;
    top: 240px;
    right: 26px;
    left: unset;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    top: 32px;
    right: 50px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 700px;
    height: 576px;
    top: 36px;
    right: 36px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 770px;
    height: 648px;
    top: 68px;
    right: 135px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    width: 978px;
    height: 810px;
    top: 138px;
    right: 202px;
  }
}
.partners-modal {
  width: 100%;
  height: 100%;
  ${(props) => props.theme.breakpoints.up('sm')} {
    width: 430px;
    height: 262px;
    top: 160px;
    left: 300px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    height: 285px;
    top: 110px;
    left: 165px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 590px;
    height: 370px;
    top: 150px;
    left: 300px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 610px;
    height: 435px;
    top: 300px;
    left: 414px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    width: 584px;
    height: 420px;
    top: 370px;
    left: 525px;
  }
  .global-modal-content {
    background-color: ${(props) => props.theme.palette.primary.light};
  }
}
.rabbit-modal {
  width: 100%;
  height: 100%;
  .animated-rabbit {
    object-fit: cover;
    width: 100%;
    height: 100%;
    ${(props) => props.theme.breakpoints.up('md')} {
      object-fit: contain;
    }
  }
  ${(props) => props.theme.breakpoints.up('sm')} {
    width: 343px;
    height: 262px;
    top: 160px;
    left: 300px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    height: 261px;
    top: 110px;
    left: 165px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 518px;
    height: 370px;
    top: 150px;
    left: 300px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 610px;
    height: 435px;
    top: 300px;
    left: 414px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    width: 584px;
    height: 420px;
    top: 370px;
    left: 525px;
  }
  .global-modal-content {
    background-color: ${(props) => props.theme.palette.primary.light};
  }
}
.team-modal {
  width: 100%;
  height: 100%;
  ${(props) => props.theme.breakpoints.up('sm')} {
    width: 454px;
    height: 290px;
    top: 206px;
    left: 188px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    top: 212px;
    left: 194px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 573px;
    height: 352px;
    top: 222px;
    left: 210px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 840px;
    height: 468px;
    top: 345px;
    left: 276px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    width: 944px;
    height: 643px;
    top: 602px;
    left: 268px;
  }
  .global-modal-content {
    background-color: ${(props) => props.theme.palette.primary.light};
  }
}
.services-modal {
  width: 100%;
  height: 100%;
  ${(props) => props.theme.breakpoints.up('sm')} {
    width: 560px;
    height: 360px;
    top: 240px;
    left: 72px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    top: 114px;
    left: 200px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 857px;
    height: 500px;
    top: 116px;
    left: 260px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 985px;
    height: 655px;
    top: 126px;
    left: 438px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    width: 1155px;
    height: 715px;
    top: 322px;
    left: 672px;
  }
  .global-modal-content {
    background-color: ${(props) => props.theme.palette.primary.main};
  }
}
.antivirus-modal {
  width: 100%;
  height: 220px;
  top: 35%;
  ${(props) => props.theme.breakpoints.up('sm')} {
    width: 470px;
    height: 240px;
    left: 25%;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 550px;
    height: 272px;
    left: 35%;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 602px;
    height: 272px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    width: 680px;
    height: 340px;
  }

  .global-modal-wrapper {
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
.minesweeper-modal {
  ${(props) => props.theme.breakpoints.up('sm')} {
    top: 115px;
    left: 186px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    top: 16px;
    left: 464px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    top: 100px;
    left: 588px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    top: 240px;
    left: 650px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    top: 456px;
    left: 916px;
  }
}
h1 {
  ${(props) => props.theme.breakpoints.up('default')} {
      font-size: 32px
  }
  ${(props) => props.theme.breakpoints.up('sm')} {
      font-size: 40px
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
      font-size: 56px
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
      font-size: 72px
  }
}
h2 {
  margin: 0;
  color: ${(props) => props.theme.palette.primary.darkLight};
  font-size: 16px;
  padding-bottom: 16px;
  letter-spacing: 2px;
 
  ${(props) => props.theme.breakpoints.up('sm')} {
      font-size: 22px;
      padding-bottom: 16px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
      font-size: 30px;
      padding-bottom: 18px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
      font-size: 32px;
      padding-bottom: 20px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
      font-size: 36px;
      padding-bottom: 26px;
  }
}
h3 {
  ${(props) => props.theme.breakpoints.up('default')} {
      font-size: 18px
  }
  ${(props) => props.theme.breakpoints.up('sm')} {
      font-size: 20px
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
      font-size: 22px
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
      font-size: 24px
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
      font-size: 32px
  }
}
h4 {
  ${(props) => props.theme.breakpoints.up('default')} {
      font-size: 16px
  }
  ${(props) => props.theme.breakpoints.up('sm')} {
      font-size: 18px
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
      font-size: 20px
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
      font-size: 28px
  }
}
h5 {
  ${(props) => props.theme.breakpoints.up('default')} {
      font-size: 14px
  }
  ${(props) => props.theme.breakpoints.up('sm')} {
      font-size: 16px
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
      font-size: 18px
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
      font-size: 24px
  }
}
h6 {
  ${(props) => props.theme.breakpoints.up('default')} {
      font-size: 14px
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
      font-size: 16px
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
      font-size: 18px
  }
}
main {
    position: relative;
    max-width: ${(props) => props.theme.breakpoints.xxl}px;
}
span {
  color: ${(props) => props.theme.palette.primary.light};
    font-size: 16px;
    padding: 3px 0;
    
  ${(props) => props.theme.breakpoints.up('lg')} {
    font-size: 18px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    font-size: 22px;
    padding: 5px 0;
  }
}
p {
  display: inline-block;
  margin: 0 !important;
  ${(props) => props.theme.palette.primary.light};

  ${(props) => props.theme.breakpoints.up('default')} {
    font-size: 16px;
    padding: 3px 0;
  }
  ${(props) => props.theme.breakpoints.up('sm')} {
    font-size: 18px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 20px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    font-size: 28px;
    padding: 5px 0;
  }
}
iframe {
  border: 0;
  width: 100%;
  height: 292px;
  margin-top: 16px;

  ${(props) => props.theme.breakpoints.up('sm')} {
    width: 216px;
    height: 202px;
    margin-top: 0;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 325px;
    height: 305px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 352px;
    height: 330px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    width: 448px;
    height: 420px;
  }
}
.gatsby-image-wrapper {
    height: 100%;
 }
 .Typewriter__cursor {
    display: none;
 }
 #overlay {
    opacity: 0;
  }
`;

export default GlobalStyle;
